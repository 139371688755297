import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { StateAttachmentUtils, TempAttachment } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { toPromise } from '@dougs/core/utils';
import { DrawerService, FlashMessagesService, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { AttachmentValidationStatus, ValidatedFile } from '@dougs/task/dto';
import { AttachmentAutoValidationState } from '@dougs/task/shared';
import { User } from '@dougs/user/dto';

@Injectable()
export class AttachmentAutoValidationService {
  constructor(
    private readonly attachmentAutoValidationState: AttachmentAutoValidationState,
    private readonly modalService: ModalService,
    private readonly drawerService: DrawerService,
    private readonly metricsService: MetricsService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  private target?: User;
  private attachmentsValidation: AttachmentValidationStatus[] = [];
  private modelName?: string;

  async validateFiles({
    fileList,
    companyId,
    model,
    target,
  }: {
    fileList: FileList;
    companyId: number;
    model: { id: number; name?: string; fileType?: string };
    target?: User;
  }): Promise<ValidatedFile[]> {
    this.target = target;
    this.modelName = model.name;
    const { attachments, fileMap }: TempAttachment = StateAttachmentUtils.getModelWithTempAttachment(
      fileList,
      companyId,
      model.id,
    );

    this.metricsService.pushMixpanelTimeEvent('Document OCR Duration');
    this.attachmentsValidation = await this.attachmentAutoValidationState.autoValidateFiles(
      attachments,
      fileMap,
      companyId,
    );
    this.metricsService.pushMixpanelEvent('Document OCR Duration');

    const invalidAttachments: AttachmentValidationStatus[] = this.attachmentsValidation.filter(
      (attachment) => attachment.isValid === false,
    );
    this.showFlashMessage(invalidAttachments.length, this.attachmentsValidation.length - invalidAttachments.length);

    await this.handleInvalidFiles(invalidAttachments);
    this.sendMetrics(fileList.length, model.fileType);
    return this.getValidatedFiles(fileMap);
  }

  private async handleInvalidFiles(invalidAttachments: AttachmentValidationStatus[]): Promise<void> {
    if (invalidAttachments.length > 0) {
      const manuallyValidated: AttachmentValidationStatus[] = await this.manualValidation(invalidAttachments);
      const validAttachments: AttachmentValidationStatus[] = this.attachmentsValidation.filter(
        (attachment) => attachment.isValid === true,
      );
      this.attachmentsValidation = [...manuallyValidated, ...validAttachments];
    }
  }

  private async manualValidation(files: AttachmentValidationStatus[]): Promise<AttachmentValidationStatus[]> {
    const openDrawer = await this.openConfirmationModal(files);
    if (!openDrawer) {
      return files;
    }
    return this.openConfirmationDrawer(files);
  }

  private async openConfirmationModal(files: AttachmentValidationStatus[]): Promise<boolean> {
    const { AttachmentInvalidModalComponent } = await import(
      '../../../modals/attachment-manual-validation/attachment-invalid-modal/attachment-invalid-modal.component'
    );
    return toPromise(
      this.modalService
        .open<boolean>(AttachmentInvalidModalComponent, { data: files })
        .afterClosed$.pipe(map((closeEvent: OverlayCloseEvent<boolean | null | undefined>) => !!closeEvent?.data)),
    );
  }

  private async openConfirmationDrawer(files: AttachmentValidationStatus[]): Promise<AttachmentValidationStatus[]> {
    const { AttachmentInvalidDrawerComponent } = await import(
      '../../../modals/attachment-manual-validation/attachment-invalid-drawer/attachment-invalid-drawer.component'
    );
    return toPromise(
      this.drawerService
        .open<AttachmentValidationStatus[]>(AttachmentInvalidDrawerComponent, {
          data: {
            files,
            target: this.target,
            modelName: this.modelName,
          },
        })
        .afterClosed$.pipe(
          map(
            (closeEvent: OverlayCloseEvent<AttachmentValidationStatus[] | null | undefined>) =>
              closeEvent?.data ?? files,
          ),
        ),
    );
  }

  private showFlashMessage(invalidFilesCount: number, validFilesCount: number): void {
    let message = '';
    if (invalidFilesCount > 0) {
      message =
        invalidFilesCount === 1
          ? "1 document n'a pas pu être analysé. "
          : `${invalidFilesCount} documents n'ont pas pu être analysés. `;
    }

    message +=
      validFilesCount === 0
        ? ''
        : validFilesCount === 1
          ? 'Client identifié dans 1 document.'
          : `Client identifié dans ${validFilesCount} documents.`;
    this.flashMessagesService.show(message, { type: 'success' });
  }

  private sendMetrics(totalCount: number, slot?: string): void {
    const validCount: number =
      this.attachmentsValidation.filter((attachment) => attachment.isValid === true)?.length ?? 0;
    const verifiedCount: number =
      this.attachmentsValidation.filter((attachment) => attachment.manualValidation === true)?.length ?? 0;
    const rejectedCount: number =
      this.attachmentsValidation.filter((attachment) => attachment.isValid === false && !attachment.manualValidation)
        ?.length ?? 0;

    this.metricsService.pushMixpanelEvent('Documents Validation', {
      Location: this.modelName,
      OCR: true,
      Slot: slot,
      Total: totalCount,
      'Auto-validated': validCount,
      Verified: verifiedCount,
      Rejected: rejectedCount,
    });
    this.metricsService.pushMixpanelEvent('Document OCR Duration');
  }

  private getValidatedFiles(fileMap: Map<string, File>): ValidatedFile[] {
    const result = this.attachmentsValidation
      .filter((detail) => detail.isValid === true || detail.manualValidation === true)
      .map((detail) => ({
        file: fileMap?.get(detail.attachment.id as string),
        validationRule: detail.validationRule,
      }))
      .filter((result): result is ValidatedFile => !!result.file);

    this.attachmentsValidation = [];
    return result;
  }
}
